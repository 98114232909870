import { render, staticRenderFns } from "./DrugBidInfo.vue?vue&type=template&id=88eeb210"
import script from "./DrugBidInfo.vue?vue&type=script&lang=js"
export * from "./DrugBidInfo.vue?vue&type=script&lang=js"
import style0 from "./DrugBidInfo.vue?vue&type=style&index=0&id=88eeb210&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports