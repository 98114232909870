<style lang="scss">
@import "~@/assets/css/var";

.bidding-info-prov {
    .my-table {
        height: 100%;

        .icon {
            color: $main;
            cursor: pointer;
        }

        .up {
            color: $red;
        }

        .down {
            color: $green;
        }
    }
}
</style>
<template>
    <div class="flex flex-item flex-column bidding-info-prov">
        <ts-table-operate :name="nameKey" :table-name="tableName" v-if="cols.length > 0"
            @searchShowLeft="searchShowLeft" :cols="cols" @handleFilter="handleFilter" show-export
            @leadingOut="exportInfo()" @handleSort="sort" @viewChange="viewChange">
            <el-button type="primary" slot="left" @click="showCompare" style="margin-right: 20px;">
                勾选对比{{ checkedList.length > 0 ? ` (已选${checkedList.length}条)` : '' }}</el-button>
            <div class="flex-item"></div>
        </ts-table-operate>
        <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
            @handleSort="sort" :error="pageParam.error" :error-code="error" :name="nameKey"
            @handleRowDbClickChange="handleRowDbClickChange" full hide-detail @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter" :isListOutQueryView="false"></ts-search-left>
            </template>
            <template v-slot:slot_price="{ props }">
                <div class="flex flex-center" style="justify-content:center">
                    <div class="flex clickable" @click="showCanvas(props.row)"
                        style="justify-content:center;margin-right: 5px;">
                        <svg style="width: 20px;height: 20px" t="1673858334212" class="icon" viewBox="0 0 1024 1024"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1514" width="128" height="128">
                            <path
                                d="M124.928 660.48h104.96v238.08h-104.96zM347.136 535.552h104.96v363.52h-104.96zM548.864 644.608h104.96v254.464h-104.96zM766.976 422.4h104.96v476.672h-104.96z"
                                :fill="props.row.clicked ? '#F11C1C' : '#5171DA'" p-id="1515"></path>
                            <path
                                d="M666.112 124.928l110.592 118.784L535.04 473.6 351.744 307.2v-0.512l-0.512-0.512-1.536 2.048L61.44 536.576l32.768 41.472 254.976-202.752 186.368 169.472 1.024-1.024 0.512 0.512 275.456-262.144 100.864 108.544 12.8-260.096z"
                                :fill="props.row.clicked ? '#F11C1C' : '#5171DA'" p-id="1516"></path>
                        </svg>
                    </div>
                </div>
            </template>
            <template v-slot:slot_compare="{ props }">
                <el-checkbox v-model="checked[props.row.id]" :true-label="props.row.id"
                    @change="checkChange(props.row.id)"></el-checkbox>
            </template>
            <template v-slot:slot_priceState="{ props }">
                <div class="flex flex-center" style="justify-content:center">
                    <el-popover v-if="props.row.priceState === '暴涨'" trigger="hover" placement="right-start"
                        width="230">
                        <el-link slot="reference" style="font-size: 11px;font-weight: bold" type="danger" size="mini"
                            :underline="false">暴涨</el-link>
                        <p class="through-page-tips through-page-tips__small">
                            最新中标价<span class="red">大于</span>均价<br>
                            最新中标价<span class="red">大于</span>上次中标价*1.5<br>
                            最新中标价<span class="red">大于等于</span>最高价<br>
                        </p>
                    </el-popover>
                    <el-popover v-else-if="props.row.priceState === '暴跌'" trigger="hover" placement="right-start"
                        width="230">
                        <el-link slot="reference" style="font-size: 11px;font-weight: bold" type="success" size="mini"
                            :underline="false">暴跌</el-link>
                        <p class="through-page-tips through-page-tips__small">
                            最新中标价<span class="red">小于</span>均价<br>
                            最新中标价<span class="red">小于</span>上次中标价*50%<br>
                            最新中标价<span class="red">小于等于</span>最低价<br>
                        </p>
                    </el-popover>
                    <el-popover v-else-if="props.row.priceState === '涨价'" trigger="hover" placement="right-start"
                        width="230">
                        <el-link slot="reference" style="font-size: 11px;font-weight: bold" type="danger" size="mini"
                            :underline="false">涨价</el-link>
                        <p class="through-page-tips through-page-tips__small">
                            最新中标价<span class="red">大于</span>均价<br>
                            最新中标价<span class="red">大于等于</span>上次中标价<br>
                            最新中标价<span class="red">小于</span>最高价<br>
                        </p>
                    </el-popover>
                    <el-popover v-else-if="props.row.priceState === '跌价'" trigger="hover" placement="right-start"
                        width="230">
                        <el-link slot="reference" style="font-size: 11px;font-weight: bold" type="success" size="mini"
                            :underline="false">跌价</el-link>
                        <p class="through-page-tips through-page-tips__small">
                            最新中标价<span class="red">小于</span>上次中标价<br>
                            最新中标价<span class="red">大于等于</span>最低价<br>
                        </p>
                    </el-popover>
                    <el-popover v-else-if="props.row.priceState === '持平'" trigger="hover" placement="right-start"
                        width="230">
                        <el-link slot="reference" style="font-size: 11px;font-weight: bold" size="mini"
                            :underline="false">持平</el-link>
                        <p class="through-page-tips through-page-tips__small">
                            最新中标价<span class="red">大于等于</span>均价<br>
                            最新中标价<span class="red">等于</span>上次中标价<br>
                            最新中标价<span class="red">小于等于</span>最高价<br>
                        </p>
                    </el-popover>
                </div>
            </template>
            <template v-slot:slot_fivePriceState="{ props }">
                <div v-if="props.row.fivePriceState === '持平'">持平</div>
                <div :class="['持续上升', '稳定上升', '震荡上升'].includes(props.row.fivePriceState) ? 'up' : 'down'">
                    {{ props.row.fivePriceState }}
                </div>
            </template>
        </ts-table>

        <ts-dialog :show.sync="bidDialog.show" title="价格趋势" width="1000px" close-on-click-modal hide-footer>
            <my-bid-compare v-if="bidDialog.show" :row="bidDialog.source" type="drug"></my-bid-compare>
        </ts-dialog>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import searchMixin from './searchMixin'
import MyBidCompare from "@/components/MyBidCompare.vue";
export default {
    components: { MyBidCompare },
    mixins: [myMixin, searchMixin],
    props: {
        baseQuery: {
            default: () => ({})
        },
        visitId: {},
    },
    watch: {
        'bidDialog.show'(val) {
            if (!val && this.bidDialog.source.id) {
                this.bidDialog.source.clicked = false
            }
        },
    },
    data() {
        return {
            nameKey: 'drugBid',
            tableName: 'aim_base_drugs_winning',
            pageApi: 'indexDrug/aimBaseDrugsWinning/queryWinningPage',
            cols: this.$help.generalCols([
                {
                    fieldName: 'compare',
                    name: '对比',
                    listClass: 'tc',
                    width: 50,
                },
                {
                    fieldName: 'price',
                    name: '价格趋势',
                    listClass: 'tc',
                    width: 70,
                },
                {
                    fieldName: 'priceState',
                    name: '近期趋势',
                    listClass: 'tc',
                    listOrderView: '是',
                    width: 90,
                },
                {
                    fieldName: 'fivePriceState',
                    name: '近5年趋势',
                    listClass: 'tc',
                    width: 90,
                },
                this.$variable.indexColumn.drug(),
                {
                    fieldName: 'compName',
                    name: '企业',
                    width: 180,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listView: '是',
                    listOrderView: '是',
                },
                {
                    fieldName: 'pack',
                    name: '制剂单位',
                    listQueryView: "是",
                    listClass: 'tc',
                    listOrderView: '是',
                },
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    width: 100,
                    listQueryView: "是",
                    listQueryModel: 1,
                    listOrderView: '是',
                },
                {
                    fieldName: 'winProvinceNumber',
                    name: '中标省份',
                    listOrderView: '是',
                    width: 100,
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'minUnitPrice',
                    name: '最新制剂价',
                    width: 80,
                    listOrderView: '是',
                    listClass: 'tc'
                },
                {
                    fieldName: 'conversionRatio',
                    name: '转换比',
                    width: 80,
                    listOrderView: '是',
                    listClass: 'tc'
                },
                {
                    fieldName: 'minPrice',
                    name: '单价(最低)',
                    listOrderView: '是',
                    width: 100,
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'avgPrice',
                    name: '单价(均价)',
                    listOrderView: '是',
                    width: 100,
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'maxPrice',
                    name: '单价(最高)',
                    listOrderView: '是',
                    width: 100,
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'winNumber',
                    name: '中标记录',
                    width: 100,
                    listOrderView: '是',
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'winningTime',
                    name: '最新中标时间',
                    width: 120,
                    listOrderView: '是',
                    listClass: 'tc'
                },
                {
                    fieldName: 'purchaseBatch',
                    name: '省份集采',
                    width: 95,
                    listOrderView: '是',
                    link: this.goNationProcurement,
                    listClass: 'tc'
                },
            ]),
            bidDialog: {
                show: false,
                source: {},
                title: '',
            },

            checked: {},
        }
    },
    computed: {
        checkedList() {
            return Object.values(this.checked).filter(item => item)
        },
        listMap() {
            const obj = {}
            this.list.forEach(item => {
                obj[item.id] = item
            })
            return obj
        },
    },
    methods: {
        checkChange(id) {
            if (this.checkedList.length > 6) {
                this.$store.commit('error', '最多只能选择6条')
                this.$set(this.checked, id, false)
            }
        },
        showCompare() {
            if (this.checkedList.length === 0) {
                this.$store.commit('error', '请至少勾选一条')
                return false
            }


            this.bidDialog.show = true
            this.bidDialog.source = this.checkedList.map(item => this.listMap[item])
        },
        exportInfo(txt = '确定导出吗') {
            this.$help.exportInfo(txt, 'indexDrug/aimBaseDrugsWinning/exportQueryWinningPage', { params: this.baseQuery })
        },
        convertData(val) {
            if (val === 0 || val === '0') {
                return ''
            }
            return val
        },
        listComputed(list) {
            return list.map(item => {
                item.purchaseBatch = this.convertData(item.purchaseBatch)
                return item
            })
        },
        showCanvas(form) {
            this.$set(form, 'clicked', true)
            this.bidDialog.show = true
            this.bidDialog.source = form
        },
        showCanvasBack(form) {
            this.bidDialog.show = true
            this.bidDialog.title = `${form.compName}-${form.comName}${form.unifySpec}（转换比：${form.conversionRatio}）价格趋势`
            this.bidDialog.source = form
            this.$api.get('indexDrug/aimBaseDrugsWinning/windingPriceTrend', { aimBaseDrugsWinningId: form.id })
                .then(res => {
                    const myChart = window.echarts.init(document.getElementById('canvas-page'))
                    myChart.clear()

                    if (res.data && res.data.length > 0) {
                        myChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                                formatter(data) {
                                    const row = res.data[data[0].dataIndex]
                                    return `${row.winningProvince}<br>${row.winningTime}<br>${row.price}`
                                },

                            },
                            xAxis: [{
                                type: 'category',
                                data: res.data.map(item => item.winningTime),
                            },],
                            yAxis: [{
                                type: 'value',
                                scale: true,
                                axisTick: { show: false },
                                axisLine: { show: false },
                                splitLine: {
                                    lineStyle: {
                                        type: 'dashed'
                                    }
                                },

                            },],
                            series: [{
                                type: 'line',
                                data: res.data.map(item => item.price).map(item => {
                                    if (item == 0) {
                                        item = ''
                                    }
                                    return item
                                }),
                                smooth: true,
                                connectNulls: true,
                            },],
                        })
                    }
                })
        },
    }
}
</script>